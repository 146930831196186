var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DefaultLayout", [
    _c(
      "div",
      {
        staticClass: "text-center py-48 text-white",
        style: _vm.backgroundImage,
        attrs: { id: "teaser-image" }
      },
      [
        _c("h1", { staticClass: "text-6xl" }, [
          _vm._v(_vm._s(_vm.$t("zelten.title")))
        ]),
        _c("p", { staticClass: "text-4xl my-4" }, [
          _vm._v(_vm._s(_vm.$t("zelten.date")))
        ]),
        _c(
          "router-link",
          {
            staticClass:
              "\n        rounded\n        my-4\n        bg-black\n        py-4\n        px-12\n        no-underline\n        text-white\n        inline-block\n        text-2xl\n      ",
            attrs: { to: { name: "registration" } }
          },
          [_vm._v(_vm._s(_vm.$t("zelten.registration")))]
        )
      ],
      1
    ),
    _c("div", { staticClass: "w-full float-left" }, [
      _c("div", { staticClass: "w-full md:w-2/5 p-6 text-center float-left" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("zelten.whereTitle")))]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.whereText")) }
        }),
        _c("a", { attrs: { href: "https://goo.gl/maps/KtGnq3SnmnXDQv4D6" } }, [
          _vm._v("Zum Routenplaner")
        ]),
        _c("h2", { staticClass: "mt-16" }, [
          _vm._v(_vm._s(_vm.$t("zelten.whenTitle")))
        ]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.whenText")) }
        })
      ]),
      _c("div", { staticClass: "w-full md:w-3/5 float-left" }, [
        _c("iframe", {
          staticClass: "w-full h-16",
          staticStyle: { border: "0", "min-height": "500px" },
          attrs: {
            src:
              "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5442.009910628762!2d7.032912163942831!3d47.0008756607872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e0e6273b7c66d%3A0xf603a3bff9a3aee6!2sTCS%20Camping%20Gampelen%20Neuenburgersee!5e0!3m2!1sde!2sch!4v1653749112522!5m2!1sde!2sch",
            allowfullscreen: "",
            loading: "lazy",
            referrerpolicy: "no-referrer-when-downgrade"
          }
        })
      ])
    ]),
    _c("div", { staticClass: "w-full float-left" }, [
      _c("div", { staticClass: "hidden md:block w-2/5 float-left" }, [
        _c("img", {
          staticClass: "w-full",
          attrs: { src: require("@/assets/images/image1.png") }
        })
      ]),
      _c("div", { staticClass: "w-full md:w-3/5 p-6 text-center float-left" }, [
        _c("h2", [_vm._v(_vm._s(_vm.$t("zelten.howTitle")))]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.howText")) }
        }),
        _c("h2", { staticClass: "mt-16" }, [
          _vm._v(_vm._s(_vm.$t("zelten.coastTitle")))
        ]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.coastText")) }
        }),
        _c("h2", { staticClass: "mt-16" }, [
          _vm._v(_vm._s(_vm.$t("zelten.whatTitle")))
        ]),
        _c("div", {
          domProps: { innerHTML: _vm._s(_vm.$t("zelten.whatText")) }
        })
      ])
    ]),
    _c("div", {
      staticClass: "w-full p-8 inline-block text-center bg-blue-500 text-white",
      domProps: { innerHTML: _vm._s(_vm.$t("zelten.contact")) }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }