<template>
  <DefaultLayout>
    <div
      id="teaser-image"
      class="text-center py-48 text-white"
      :style="backgroundImage"
    >
      <h1 class="text-6xl">{{ $t('zelten.title') }}</h1>
      <p class="text-4xl my-4">{{ $t('zelten.date') }}</p>
      <router-link
        :to="{ name: 'registration'}"
        class="
          rounded
          my-4
          bg-black
          py-4
          px-12
          no-underline
          text-white
          inline-block
          text-2xl
        "
        >{{ $t('zelten.registration') }}</router-link
      >
    </div>
    <div class="w-full float-left">
      <div class="w-full md:w-2/5 p-6 text-center float-left">
        <h2>{{ $t('zelten.whereTitle') }}</h2>
        <div v-html="$t('zelten.whereText')"></div>
        <a href="https://goo.gl/maps/KtGnq3SnmnXDQv4D6">Zum Routenplaner</a>
        <h2 class="mt-16">{{ $t('zelten.whenTitle') }}</h2>
        <div v-html="$t('zelten.whenText')"></div>
      </div>
      <div class="w-full md:w-3/5 float-left">
        <iframe
          class="w-full h-16"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5442.009910628762!2d7.032912163942831!3d47.0008756607872!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x478e0e6273b7c66d%3A0xf603a3bff9a3aee6!2sTCS%20Camping%20Gampelen%20Neuenburgersee!5e0!3m2!1sde!2sch!4v1653749112522!5m2!1sde!2sch"
          style="border: 0; min-height: 500px"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
    <div class="w-full float-left">
      <div class="hidden md:block w-2/5 float-left">
        <img class="w-full" :src="require(`@/assets/images/image1.png`)" />
      </div>
      <div class="w-full md:w-3/5 p-6 text-center float-left">
        <h2>{{ $t('zelten.howTitle') }}</h2>
        <div v-html="$t('zelten.howText')"></div>
        <h2 class="mt-16">{{ $t('zelten.coastTitle') }}</h2>
        <div v-html="$t('zelten.coastText')"></div>
        <h2 class="mt-16">{{ $t('zelten.whatTitle') }}</h2>
        <div v-html="$t('zelten.whatText')"></div>
      </div>


    </div>
    <div
      class="w-full p-8 inline-block text-center bg-blue-500 text-white"
      v-html="$t('zelten.contact')"
    ></div>
  </DefaultLayout>
</template>

<script>
export default {
  name: 'Zelten',
  computed: {
    backgroundImage() {
      return (
        'background-image: url("' +
        require(`@/assets/images/header.jpg`) +
        '");' +
        'background-position: center center;' +
        'background-size: cover;'
      );
    },
  },
};
</script>
